<div style="position: relative;padding-left: 10px;" class="filter-section">
    <div class="filter-section-left" style="display: flex;">
        <i (click)="reload()" style="cursor: pointer; font-size: 20px;margin-top: 8px;"
            class="fa fa-redo-alt left-chevron"></i><input style="margin-left: -2px;" [disabled]="showLoader"
            class="filter-input" (keyup)="searchLink($event)" [(ngModel)]="searchTerm" placeholder="Search..." />
        <div *ngIf="showLoader" style="margin-top: 8px;"
            class="ml-2 h-100 d-flex flex-column justify-content-center align-items-center">
            <div style="height: 24px;width: 24px;" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

    </div>
    <div class="filter-section-right">
        <button (click)="onAddApp('')" class="btn btn-primary float-right mr-2">Global</button>

    </div>


</div>
<div class="container-fluid">
    <table *ngIf="patients" class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                    sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of resultList$ | async">
                <td style="max-width: 200px; padding-top: 7px">
                    <span class="goalname">
                        <ngb-highlight [result]="p.fullname" [term]="tableService.searchTerm"></ngb-highlight>
                    </span>
                </td>
                <td>
                    <table *ngFor="let l of p.links">
                        <tr>
                            <td>
                                 <ngb-highlight [result]="l.title"
                                    [term]="tableService.searchTerm"></ngb-highlight>
                            </td>
                        </tr>
                    </table>
                </td>
                <td>
                    <table>
                        <tr>

                            <td>
                                <i (click)="onAddApp(p.userid)" title="Edit" class="fa fa-edit mr-3 cursor-pointer"
                                    aria-hidden="true"></i>
                            </td>
                        </tr>
                    </table>
                </td>

            </tr>
        </tbody>
    </table>

    <div *ngIf="patients" class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
            [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

        <select class="custom-select pagination"
            style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
            [(ngModel)]="tableService.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
        </select>
    </div>
</div>