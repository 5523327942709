import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-warning-modal",
    templateUrl: "./warning-modal.component.html",
    styleUrls: ["./warning-modal.component.scss"],
})
export class WarningModalComponent {
    constructor(private activeModal: NgbActiveModal) { }

    @Input() Title: string = "Warning";
    @Input() Message: string = "Are You Sure?";
    @Input() OKButton: string = "OK";
    @Input() CancelButton: string = "Cancel";
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() Header: boolean = true;
    public onOK() {
        this.Response.emit({ proceed: true });
        this.activeModal.close();
    }

    public onCancel() {
        this.Response.emit({ proceed: false });
        this.activeModal.close();
    }
}
