<div *ngIf="Header" class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">{{ Title }}</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">{{ Message }}</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ CancelButton }}</button>

    <button type="button" class="btn btn-primary" (click)="onOK()">{{ OKButton }}</button>
</div>