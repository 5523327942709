import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AppsService } from "../../services/apps.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "add-app-modal",
    templateUrl: "./add-app-modal.component.html",
    styleUrls: ["./add-app-modal.component.scss"],
})
export class AddAppModalComponent implements OnInit {
    @Input() AppData: any = {};
    @Input() EditForm = false;
    @Output() readonly Response = new EventEmitter<any>();

    constructor(private fb: UntypedFormBuilder, private apps: AppsService, private activeModal: NgbActiveModal, private sessionService: SessionService) { }
    errorMessage: string = "";
    appForm = this.fb.group({
        appTitle: ["", Validators.required],
        appDescription: ["", Validators.required],
        appAction: ["", Validators.required],
        appImageUrl: ["", Validators.required],
        appActionButtonText: [""],
        appActionUrliOS: [""],
        appActionUrlAndroid: [""],
        appInfoButtonText: [""],
        appInfoButtonUrl: [""],
        linktype: ["", Validators.required],
    });
    linktype = "";
    action = "";
    currentUser = this.sessionService.currentUser;
    ngOnInit() {
        // console.log(this.AppData);
        if (this.currentUser.attributes.membership.telerx.telerxtype === 'application') {
            this.linktype = 'application';
        }
        this.AppData.actionurlandroid = this.AppData.actionurlandroid == "null" ? "" : this.AppData.actionurlandroid;
        this.AppData.actionurlios = this.AppData.actionurlios == "null" ? "" : this.AppData.actionurlios;
        if (this.EditForm) {
            setTimeout(() => {
                this.linktype = this.AppData?.linktype;
            }, 100);
        }
        // if (this.AppData.linktype=='global') {
        //     this.global = "1";
        // } else {
        //     this.global = "0";
        // }
        if (this.AppData?.action) {
            this.action = this.AppData?.action;
        }
    }
    public onSubmit() {
        this.errorMessage = "";
        if (this.appForm.valid) {
            // if (this.global === "0") {
            //     this.appForm.patchValue({ appGlobal: false });
            // } else {
            //     this.appForm.patchValue({ appGlobal: true });
            // }
            if (this.EditForm) {
                this.apps.updateApp(this.appForm, this.AppData.appid).subscribe(
                    (data) => {
                        this.Response.emit({ success: true, message: "Application Updated" });
                        this.activeModal.close();
                    },
                    (err) => {
                        this.errorMessage = err[0].title;
                    }
                );
            } else {
                this.apps.createApp(this.appForm).subscribe(
                    (data) => {
                        this.Response.emit({ success: true, message: "Application Added" });
                        this.activeModal.close();
                    },
                    (err) => {
                        this.errorMessage = err[0].detail;
                    }
                );
            }
        } else {
            // console.log(this.appForm);
            this.errorMessage = "Please fill in mandatory fields.";
        }
    }

    public onCancel() {
        this.activeModal.close();
    }
}
