<div class="container-fluid">

    <div class="tab">
        <a class="tablinks active" [routerLink]="['/administration']">
            <img src="/assets/msx/admin-white.svg">Administration
        </a>
        <a class="tablinks" [routerLink]="['/schedule-report']">
            <img src="/assets/msx/automated.svg">Automated Reports
        </a>
    </div>

    <div class="row">
        <div class="input-group col-6 mb-3"></div>
        <div style="display: block; text-align: right" class="input-group col-6 mb-3"></div>
    </div>
    <div class="row">

        <div style="align-items: center;display: flex;justify-content: center;" class="col-12">
            <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="!NoRecordsFound">
        <table class="table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let g of resultList$ | async">
                    <td>{{g.name}}</td>
                    <td>
                        <input (change)="updateFutureVisit($event,g.groupid)" type="checkbox"
                            [checked]="g.futurevisits">
                    </td>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="resultsLength" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5"
                [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>

            <select class="custom-select pagination"
                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="pageSize"
                (ngModelChange)="setPageSize()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>

    <div *ngIf="NoRecordsFound">No records found</div>
</div>