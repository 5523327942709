<div>
    <div *ngIf="showMessagingAlert" id="globalAlert" class="alert alert-warning m-0 border-0">
        {{ messagingAlertText }}
        <button type="button" class="close" aria-label="Close" (click)="closeMessagingSupportAlert()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-sidebar-container smartHeight="calc(100vh - (#globalAlert)" style="overflow: hidden" class="" [animate]="false">
        <ng-sidebar #sidebar [(opened)]="_opened" position="left" mode="push" class="sidebar" dock="true" [dock]="true"
            [dockedSize]="'100px'">
            <div>
                <div class="brand">
                    <img src="../../../assets/logo-dashboard.svg" *ngIf="_opened" />
                    <!--<p class="logo-title">{{ environment.dashboard_name }}</p>-->
                </div>
                <div class="nav flex-column nav-pills" id="v-pills-tab" aria-orientation="vertical" *ngIf="_opened">
                    <!-- <div class="row-gradient"></div> -->
                    <ng-container *ngFor="let menu of menuItems.menus | menuItemsPipe">
                        <a *ngIf="menu.displayName!='Patients' && menu.displayName!='Patient Communication' && !menu.hasSubMenu && (menu.displayName!='Links' || user.attributes.membership.telerx.telerxtype!='application')"
                            class="nav-link menuitem" [class.active]="router.url === '/' + menu.route" id="v-home-tab"
                            data-toggle="pill" aria-selected="true" [routerLink]="['/' + menu.route]">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>{{ menu.displayName }}</span>
                        </a>

                        <a *ngIf="menu.displayName=='Patient Communication' && !menu.hasSubMenu && getDashboardModules('communications')"
                            class="nav-link menuitem" [class.active]="router.url === '/' + menu.route" id="v-home-tab"
                            data-toggle="pill" aria-selected="true" [routerLink]="['/' + menu.route]">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>{{ menu.displayName }}</span>
                        </a>


                        <a *ngIf="menu.displayName=='Patients' && getDashboardModules('experience_survey') && !menu.hasSubMenu && (menu.displayName!='Links' || user.attributes.membership.telerx.telerxtype!='application')"
                            class="nav-link menuitem" [class.active]="router.url === '/' + menu.route" id="v-home-tab"
                            data-toggle="pill" aria-selected="true" [routerLink]="['/' + menu.route]">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>{{ menu.displayName }}</span>
                        </a>
                        <a *ngIf="menu.displayName === 'Patient Escalation' && menu.hasSubMenu && getDashboardModules('communications')"
                            class="nav-link menuitem telerx" [class.active]="router.url === '/' + menu.route"
                            id="v-home-tab" data-toggle="pill" aria-selected="true" [routerLink]="['/waiting-room']">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Patient Escalations</span> <br />
                        </a>
                        <div
                            *ngIf="menu.displayName === 'Patient Escalation' && menu.hasSubMenu && getDashboardModules('communications')">

                            <a class="nav-link menuitem"
                                [class.active]="router.url === '/waiting-room-referrals/incoming'" id="v-onboarding-tab"
                                data-toggle="pill" [routerLink]="['/service-recovery']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>SERVICE RECOVERY</span>
                            </a>
                            <div *ngFor="let r of waitingRooms">
                                <!-- <img src="/assets/menuIcons/queues.png"> -->
                                <a class="nav-link menuitem" [class.active]="router.url === '/waiting-room/' + r.wrid"
                                    id="v-onboarding-tab" data-toggle="pill" [routerLink]="['/waiting-room', r.wrid]">
                                    <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                    <span>{{ r.roomname.toUpperCase() }}</span>
                                </a>
                            </div>
                            <!-- <a class="nav-link menuitem"
                                [class.active]="router.url === '/waiting-room-referrals/incoming'" id="v-onboarding-tab"
                                data-toggle="pill" [routerLink]="['/waiting-room-referrals', 'incoming']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>INCOMING REFERRALS</span>
                            </a>
                            <a class="nav-link menuitem"
                                [class.active]="router.url === '/waiting-room-referrals/outgoing'" id="v-onboarding-tab"
                                data-toggle="pill" [routerLink]="['/waiting-room-referrals', 'outgoing']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>OUTGOING REFERRALS</span>
                            </a> -->
                            <!-- <a class="nav-link menuitem"
                                [class.active]="router.url === '/waiting-room/' + '00000000-0000-0000-0000-000000000000'"
                                id="v-onboarding-tab" data-toggle="pill" [routerLink]="['/waiting-room']">
                                <span style="margin-left: 40px">VIEW ALL</span>
                            </a> -->
                        </div>

                        <a *ngIf="menu.displayName === 'Patient Experience' && menu.hasSubMenu && getDashboardModules('experience_survey')"
                            class="nav-link menuitem telerx" [class.active]="router.url === '/favorites?type=survey'"
                            id="v-home-tab" data-toggle="pill" aria-selected="true" [routerLink]="['/favorites']"
                            [queryParams]="{type: 'survey',graph: 'default'}">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Patient Experience</span> <br />
                        </a>
                        <div
                            *ngIf="menu.displayName === 'Patient Experience' && menu.hasSubMenu && getDashboardModules('experience_survey')">
                            <a class="nav-link menuitem" [class.active]="router.url === '/survey-data'"
                                id="v-onboarding-tab" data-toggle="pill" [routerLink]="['/survey-data']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>SURVEY DATA</span>
                            </a>
                            <a *ngIf="getDashboardModules('experience_survey')" class="nav-link menuitem"
                                [class.active]="router.url === '/msx/reports'" id="v-onboarding-tab" data-toggle="pill"
                                [routerLink]="['/msx/reports']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>REPORTS</span>
                            </a>

                            <!-- <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>ADMINISTRATION</span>
                            </a> -->

                        </div>


                        <a *ngIf="menu.displayName === 'Patient Visits' && menu.hasSubMenu && getDashboardModules('experience_survey')"
                            [routerLink]="['/favorites']" [queryParams]="{type: 'visit',graph: 'default'}"
                            class="nav-link menuitem telerx" id="v-home-tab" data-toggle="pill" aria-selected="true"
                            [class.active]="router.url === '/favorites?type=visit'">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Patient visits</span> <br />
                        </a>
                        <div
                            *ngIf="menu.displayName === 'Patient Visits' && menu.hasSubMenu && getDashboardModules('experience_survey')">
                            <a class="nav-link menuitem" [class.active]="router.url === '/visit-data'"
                                id="v-onboarding-tab" data-toggle="pill" [routerLink]="['/visit-data']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>visit data</span>
                            </a>
                        </div>

                        <a *ngIf="menu.displayName === 'Patient Billing' && menu.hasSubMenu && getDashboardModules('revenue')"
                            [class.active]="router.url === '/favorites?type=billing'" class="nav-link menuitem telerx"
                            id="v-home-tab" data-toggle="pill" aria-selected="true" [routerLink]="['/favorites']"
                            [queryParams]="{type: 'billing',graph: 'default'}">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Patient Billing</span> <br />
                        </a>
                        <div
                            *ngIf="menu.displayName === 'Patient Billing' && menu.hasSubMenu && getDashboardModules('revenue')">
                            <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill"
                                [routerLink]="['/billing-data']" [class.active]="router.url === '/billing-data'">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>billing data</span>
                            </a>
                        </div>



                        <a *ngIf="msxAdmin  &&  menu.displayName === 'Administration' && menu.hasSubMenu"
                            [routerLink]="['/administration']" class="nav-link menuitem telerx" id="v-home-tab"
                            data-toggle="pill" aria-selected="true">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Administration</span> <br />
                        </a>


                        <a *ngIf="(msxAdmin || getDashboardModules('experience_survey')) && menu.displayName === 'Socialmedia' && menu.hasSubMenu"
                            [routerLink]="['/media/activity']" class="nav-link menuitem telerx" id="v-home-tab"
                            data-toggle="pill" aria-selected="true">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                src="../../../assets/msx/social.svg" />
                            <span>SOCIAL MEDIA ACTIVITY</span> <br />
                        </a>

                        <div
                            *ngIf="(msxAdmin ||  getDashboardModules('experience_survey'))  &&  menu.displayName === 'Socialmedia'">
                            <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill"
                                [routerLink]="['/media/reviews']" [class.active]="router.url === '/media/reviews'">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>RESPOND TO REVIEWS</span>
                            </a>
                        </div>


                        <a *ngIf="menu.displayName === 'Patient Engagement' && menu.hasSubMenu"
                            class="nav-link menuitem telerx" id="v-home-tab" data-toggle="pill" aria-selected="true">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Patient Engagement</span> <br />
                        </a>
                        <div *ngIf="menu.displayName === 'Patient Engagement' && menu.hasSubMenu">
                            <!-- <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill">
                                <span style="margin-left: 40px">Patient Outreach</span>
                            </a>
                            <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill">
                                <span style="margin-left: 40px">AD HOC SURVEYS</span>
                            </a> -->
                            <a class="nav-link menuitem" [class.active]="router.url === '/orders'" id="v-onboarding-tab"
                                data-toggle="pill" [routerLink]="['/orders']">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>PATIENT RESOURCES</span>
                            </a>
                            <a class="nav-link menuitem" [class.active]="router.url === '/goals'" id="v-onboarding-tab"
                                [routerLink]="['/goals']" data-toggle="pill">
                                <img class="menuIcon" src="/assets/menuIcons/queues.png">
                                <span>HEALTH GOALS</span>
                            </a>
                        </div>

                        <!-- <a *ngIf="menu.displayName === 'Administration' && menu.hasSubMenu"
                            class="nav-link menuitem telerx" id="v-home-tab" data-toggle="pill" aria-selected="true">
                            <i *ngIf="menu.iconType === 'fontawesome'" class="{{ menu.class }}"></i>
                            <img class="menuIcon" *ngIf="menu.iconType === 'image'"
                                [src]="router.url === '/' + menu.route ? menu.inActiveIcon : menu.activeIcon" />
                            <span>Administration</span> <br />
                        </a>
                        <div *ngIf="menu.displayName === 'Administration' && menu.hasSubMenu">
                            <a class="nav-link menuitem" id="v-onboarding-tab" data-toggle="pill">
                                <span style="margin-left: 40px">PATIENT STATS</span>
                            </a>
                        </div> -->
                        <div *ngIf="showManagement && menu.displayName === 'Management' && menu.hasSubMenu">
                            <div *ngFor="let t of teleRemedie; let i = index">
                                <a *ngIf="t.issubscribed && t.isenabled && t.providerdashboardurl !== null && t.providerdashboardurl !== ''"
                                    class="nav-link menuitem" [class.active]="activeStateCheck(t.providerdashboardurl)"
                                    id="v-home-tab" data-toggle="pill" aria-selected="true"
                                    [routerLink]="['/session', t.providerdashboardurl]">
                                    <i class="fas fa-2x fa-fw fa-tasks" style="font-size: 15pt"></i>
                                    <span class="management-link">{{ t.name }}</span>
                                </a>
                            </div>
                        </div>
                        <!-- 
                        <a
                            *ngIf="showManagement && menu.hasSubMenu"
                            style="display: table; padding-bottom: 0px; position: relative"
                            class="nav-link menuitem telerx"
                            id="v-home-tab"
                            data-toggle="pill"
                            aria-selected="true"
                        >
                            <ul style="list-style-type: none; margin: 0px 0px 0px -40px" *ngFor="let t of teleRemedie; let i = index">
                                <li
                                    *ngIf="userRole !== 'admin' && t.issubscribed && t.isenabled && t.providerdashboardurl !== null && t.providerdashboardurl !== ''"
                                    style="line-height: 1px; display: table"
                                >
                                    &nbsp;
                                    <i class="fas fa-2x fa-fw fa-tasks" style="font-size: 15pt; float: left"></i>
                                    <a
                                        style="
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            width: 80%;
                                            line-height: 1px;
                                            text-transform: capitalize;
                                            float: left;
                                            margin-left: -10px;
                                            margin-top: 4px;
                                        "
                                        class="nav-link menuitem"
                                        id="v-onboarding-tab"
                                        data-toggle="pill"
                                        [routerLink]="['/session', t.providerdashboardurl]"
                                    >
                                        {{ t.name }}</a
                                    >
                                </li>
                                <li *ngIf="userRole === 'admin' && t.issubscribed && t.isenabled && t.admindashboardurl !== null && t.admindashboardurl !== ''" style="line-height: 1px">
                                    &nbsp;
                                    <a
                                        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 80%; line-height: 1px; text-transform: capitalize"
                                        class="nav-link menuitem"
                                        id="v-onboarding-tab"
                                        data-toggle="pill"
                                        [routerLink]="['/session', t.admindashboardurl]"
                                    >
                                        {{ t.name }}</a
                                    >
                                </li>
                            </ul>
                        </a> -->
                    </ng-container>

                    <!-- <a
                        *ngIf="_activeCareTeam?.covidtype !== 'FREE'"
                        class="nav-link menuitem"
                        id="v-campaign-tab"
                        data-toggle="pill"
                        [class.active]="router.url.indexOf('campaign-manager') !== -1"
                        [routerLink]="['/session/', campaignURL]"
                    >
                        <img [src]="router.url === '/session' ? '../../assets/icons/nav_campaign_active.png' : '../../assets/icons/nav_campaign_inactive.png'" />
                        <span>Campaign Manager</span>
                    </a> -->
                </div>
            </div>
        </ng-sidebar>
        <!-- Page content -->

        <div style="margin-left: 10px;" ng-sidebar-content>
            <div class="container-fluid">
                <div class="d-flex justify-content-between align-items-center mt-2">
                    <button (click)="blur(_blurStatus)" class="btn"><img src="/assets/msx/eyeon.svg" />{{ _blurStatus ?
                        "Privacy
                        Mode OFF" : "Privacy Mode ON" }}</button>
                    <div class="d-flex align-items-center">
                        <div *ngIf="!sessionService.isAdmin() && !sessionService.isSuperAdmin() && (getDashboardModules('experience_survey') && getDashboardModules('communications'))"
                            class="unread-message">
                            <!-- Unread Messages -->
                            <!--   <a routerLink="/chat">
                                <span *ngIf="negativeReview > 0" class="badge">{{ negativeReview }}</span>{{
                                negativeReview > 0 ? "Negative Reviews" : "No Negative Reviews" }}
                            </a>-->
                            <a routerLink="/chat">
                                <span *ngIf="unreadMessagesCount > 0" class="badge">{{ unreadMessagesCount }}</span>{{
                                unreadMessagesCount > 0 ? "Unread Messages" : "No Unread Messages" }}
                            </a>

                        </div>
                        <div id="navbarContent">
                            <div class="nav-item" ngbDropdown>
                                <a class="d-flex align-items-center dropdown-toggle" style="cursor: pointer"
                                    ngbDropdownToggle id="navbarDropdown1" role="button">
                                    <ngx-avatar class="mr-2 avatar" [src]="profilPicUrl" [name]="getUserInitials()"
                                        size="34" [round]="true" bgColor="#0A529E"> </ngx-avatar>
                                    <span class="d-flex flex-column mr-3 ml-1">
                                        <span class="username"> <app-selected-group
                                                [entities]="entities"></app-selected-group></span>
                                        <span class="useremail">{{ username }}</span>
                                        <!--<small class="text-muted" style="text-transform: uppercase">{{
                                                _activeCareTeam?.careteamname }}</small> -->
                                    </span>
                                </a>
                                <div ngbDropdownMenu aria-labelledby="navbarDropdown1"
                                    class="dropdown-menu position-absolute dropdown-menu-right">
                                    <!--<div *ngIf="!sessionService.isAdmin() && !sessionService.isSuperAdmin()"
                                            class="py-1">
                                            <small class="px-4 text-muted">Care Teams</small>
                                            <a [class.disabled]="isDisabled(c)" ngbDropdownItem href="#"
                                                (click)="$event.preventDefault(); activeCareTeam(c)"
                                                *ngFor="let c of careteams | orderBy: 'careteamname'">
                                                <i class="fas fa-check mr-1"
                                                    *ngIf="_activeCareTeam?.careteamname === c.careteamname"></i>
                                                {{ c.careteamname }}
                                            </a>
                                        </div> -->
                                    <!-- <div class="dropdown-divider mx-3"></div>
                                    <div class="py-1">
                                        <small class="px-4 text-muted">Two-Factor Auth</small>
                                        <div class="mt-1 px-4">
                                            <i class="fas fa-circle mr-2"
                                                [ngClass]="user.attributes?.twofactorenabled ? 'text-success' : 'text-secondary'"></i>
                                            <span>{{ user.attributes?.twofactorenabled ? "Enabled" : "Not enabled"
                                                }}</span>
                                        </div>
                                        <div class="dropdown-divider mx-3"></div>-->
                                    <!-- <div class="py-1">
                                        <small class="px-4 text-muted">Two-Factor Auth</small>
                                        <div class="mt-1 px-4">
                                            <i class="fas fa-circle mr-2"
                                                [ngClass]="user.attributes?.twofactorenabled ? 'text-success' : 'text-secondary'"></i>
                                            <span>{{ user.attributes?.twofactorenabled ? "Enabled" : "Not enabled"
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider mx-3"></div> -->
                                    <a ngbDropdownItem href="#"
                                        (click)="$event.preventDefault(); onEnableTwoFactorAuth()"
                                        *ngIf="!user.attributes?.twofactorenabled">Enable Two-Factor Auth</a>
                                    <a ngbDropdownItem href="#"
                                        (click)="$event.preventDefault(); onDisableTwoFactorAuth()"
                                        *ngIf="user.attributes?.twofactorenabled">Disable Two-Factor Auth</a>
                                    <a ngbDropdownItem href="#" (click)="$event.preventDefault(); changePassword()">
                                        Change Password</a>
                                    <a ngbDropdownItem href="#" (click)="$event.preventDefault(); updateUserSettings()">
                                        <img src="/assets/msx/admin.svg" />Settings</a>
                                    <a ngbDropdownItem href="#" (click)="$event.preventDefault(); logout()">
                                        <img src="/assets/msx/logout.svg" />Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div #main_container>
                <h2 class="heading">{{heading=="msx"?"Reports":heading}}
                    <div *ngIf="this.router.url!=='/administration'">

                        <app-selected-group [entities]="entities"></app-selected-group>
                    </div>
                </h2>
                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-sidebar-container>
</div>

<div *ngIf="showTwoFactorAuthReminder" class="two-factor-auth-reminder">
    <p>
        {{ twoFactorAuthReminderText }}
    </p>
    <div class="d-flex justify-content-end">
        <button class="btn btn-outline-light mr-2" (click)="closeTwoFactorAuthReminder(false)">Dismiss</button>
        <button class="btn btn-light" (click)="closeTwoFactorAuthReminder(true)">Enable</button>
    </div>
</div>